import * as React from "react"

import { cn } from "../../src/@/lib/utils"

const Input = React.forwardRef(({ className, type, ...props }, ref) => {
  return type === "textarea" ? (
    <textarea
      className={cn(
        "flex h-auto w-full rounded-md border border-neutral-500 bg-white px-3 py-2 text-sm ring-offset-white placeholder:text-neutral-1000 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-plum-900 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:bg-slate-950 dark:ring-offset-slate-950 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300",
        className
      )}
      ref={ref}
      {...props}
    />
  ) : (
    <input
      type={type}
      className={cn(
        "flex h-10 w-full rounded-md border border-neutral-500 bg-white px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-neutral-1000 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-plum-900 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:bg-slate-950 dark:ring-offset-slate-950 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300",
        className
      )}
      ref={ref}
      {...props}
    />
  );
});
Input.displayName = "Input"

export { Input }
