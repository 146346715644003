
export default {
    DASHBOARD:true,
    PAYROLL:true,
    TEAM_MANAGEMENT:true,
    PROFIL_MANAGMENT:true,
    TALENT_SPHERE:true,
    TASK_MANAGMENT:true,
    EMPLOYEE_ONBOARDING:true,
    SELF_SERVICE_HUB:true,
    LEAVE_MANAGMENT:true,
    EMPLOYEE_OFFBOARDING:true,
    OFFICE_SETTING:true,
    ATTENDANCE:true,
    DAILY_TASK_REPORT:true,
    REPORTS:false,
    PERFORMANCE_MANAGEMENT:false,
    PEOPLE_ENGAGEMENT:false,
    PERSONAL_DEVELOPMENT:false,
    DAILY_TASK_REPORT:false,



    TS_PERSONAL_REQUISITION:false,
    TS_REFERRALS:false,
    TS_ON_BOARDING:false,
    TS_JOBS:true,
    TS_APPLICANTS:true,
};